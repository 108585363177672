<template>
  <v-app>
    <Header/>
    <v-container fill-height fluid>

      <v-row :align="alignment" :justify="justify">
        <v-col cols="12" lg="5" :align="alignment" :justify="justify">
  <!--        <v-card class="elevation-12 pt-5"
                  :width="width"
                  :outlined="outlined"
                  :dense="dense">-->
          <v-card class="pa-3 forgot-password-form" elevation="10" style="border-top: 3px solid #00F">
            <v-card-title class="d-flex justify-center"><h1>{{$t('msg.forgot_password_header')}}</h1></v-card-title>
            <v-alert v-show="showInvalidDataError" dense type="warning">
              {{$t('error.register_username_details_does_not_match')}}
            </v-alert>

            <v-alert v-show="showInvalidUserError" dense type="warning">
              {{$t('error.register_username_not_found')}}
            </v-alert>
            <v-alert v-show="invalidUsernameEmail" dense type="warning">
              {{$t('error.invalid_username_email')}}
            </v-alert>
  <!--          <div v-if="clientLogo" class="layout column align-center">
              <img :src="getClientLogoImageUrl()" class="loginLogoStyle" alt="logo"/>
            </div>-->
            <ValidationObserver ref="observer" v-slot="{ passes}">
              <v-card-text>
                <v-form>
                  <ValidationProvider :rules="{required:true, min:6, max:15, regex: /^[a-zA-Z0-9!#@-]+$/}" v-slot="{errors, failedRules}">
                    <v-text-field append-icon="person" name="name" type="text"
                                  autocomplete="new-password" v-model="forgotPasswordDto.username"
                                  :label="$t('msg.username')" v-on:keyup.enter="passes(forgotPassword)"
                                  :error-messages="$translatedErrorMessages(failedRules,'username')">
                    </v-text-field>
                  </ValidationProvider>
                  <ValidationProvider :rules="{required : true, regex: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/}" v-slot="{errors, failedRules}">
                    <v-text-field append-icon="person" name="email_address" type="text"
                                  v-model="forgotPasswordDto.emailAddress"
                                  :label="$t('msg.email_address')" v-on:keyup.enter="passes(forgotPassword)"
                                  :error-messages="$translatedErrorMessages(failedRules,'email_address')">
                    </v-text-field>
                  </ValidationProvider>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <v-col>
                    <v-btn class="primary mr-5" @click="passes(forgotPassword)" :loading="loading" :disabled="loading" width="52%">{{ $t('msg.forgot_password') }}</v-btn>
                    <v-btn color="secondary" @click="cancelReset" :loading="loading" :disabled="loading" width="40%">{{ $t('msg.cancel') }}</v-btn>
                  </v-col>
                </v-row>
  <!--              <v-row>
                  <v-col class="text-center">
                    <v-btn class="primary mr-5" @click="passes(forgotPassword)">{{ $t('msg.forgot_password')}}</v-btn>
                    <v-btn class="secondary" @click="cancelReset">{{ $t('msg.cancel')}}</v-btn>
                  </v-col>
                </v-row>-->
              </v-card-actions>
            </ValidationObserver>
          </v-card>
        </v-col>
      </v-row>
      <v-snackbar v-model="invalidUserCredentials" :timeout="timeout" top center color="error">
        {{text}}
        <v-btn color="white" text @click="invalidUserCredentials = false">{{ $t('msg.close_label') }}</v-btn>
      </v-snackbar>
    </v-container>
    </v-app>
</template>

<script>
import {userService} from "../../services"
import {ValidationProvider, ValidationObserver} from 'vee-validate';

import Header from "./Header";

export default {
  name: "Login",
  components: {
    ValidationProvider,
    ValidationObserver,
    Header
  },
  data() {
    return {
      invalidUserCredentials: false,
      text: 'Invalid username or password',
      timeout: 6000,
      loading: false,
      alignment: 'center',
      dense: false,
      justify: 'center',
      outlined: false,
      width: 400,
      forgotPasswordDto: {
        username:'',
        emailAddress:''
      },
      clientLogo: undefined,
      productLogo: undefined
    }
  },
  methods: {
    cancelReset () {
      this.$router.push('/')
    },
    forgotPassword: function () {
      this.loading = true
      userService.forgotPassword(this.forgotPasswordDto)
          .then(response => {
            this.loading = false
            if(response)
              this.$router.push({ path: `/confirmPassword`,
                query: {
                  username: this.forgotPasswordDto.username,
                  emailAddress: this.forgotPasswordDto.emailAddress,
                },
              })
          }).catch(error => {
        this.loading = false
        console.log(error)
        error.showResult = true
        this.invalidUserCredentials = true
        this.loading = false
      })
    },
  },
  created() {
  }
}
</script>

<style scoped>
.imageSize {
  width: 138px;
  height: 70px;
}
.loginLogoStyle {
  height: 70px;
}
</style>
