<template>
    <v-card color="grey lighten-4">
        <v-toolbar height="100px">
            <v-row>
                <v-col>
                    <img class="imageSize" src="../../assets/mdxg.png">
                </v-col>
            </v-row>
        </v-toolbar>
    </v-card>
</template>

<script>
    export default {
        name: "Header"
    }
</script>

<style>
.imageSize {
    height: 70px;
    padding-left: 30px;
}
</style>
