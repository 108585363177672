var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('Header'),_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":_vm.alignment,"justify":_vm.justify}},[_c('v-col',{attrs:{"cols":"12","lg":"5","align":_vm.alignment,"justify":_vm.justify}},[_c('v-card',{staticClass:"pa-3 forgot-password-form",staticStyle:{"border-top":"3px solid #00F"},attrs:{"elevation":"10"}},[_c('v-card-title',{staticClass:"d-flex justify-center"},[_c('h1',[_vm._v(_vm._s(_vm.$t('msg.confirm_password_header')))])]),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.showInvalidDataError),expression:"showInvalidDataError"}],attrs:{"dense":"","type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('error.register_username_details_does_not_match'))+" ")]),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.showInvalidUserError),expression:"showInvalidUserError"}],attrs:{"dense":"","type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('error.register_username_not_found'))+" ")]),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.invalidOtp),expression:"invalidOtp"}],attrs:{"dense":"","type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('error.invalid_otp'))+" ")]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-card-text',[_c('v-form',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('v-text-field',{attrs:{"append-icon":"person","name":"name","type":"text","readonly":"","autocomplete":"new-password","label":_vm.$t('msg.username'),"error-messages":_vm.$translatedErrorMessages(failedRules,'username')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return passes(_vm.confirmPassword)}},model:{value:(_vm.confirmPasswordDto.username),callback:function ($$v) {_vm.$set(_vm.confirmPasswordDto, "username", $$v)},expression:"confirmPasswordDto.username"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{required : true/*, regex: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/*/}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('v-text-field',{attrs:{"append-icon":"mdi-email","name":"email_address","type":"text","readonly":"","label":_vm.$t('msg.email_address'),"error-messages":_vm.$translatedErrorMessages(failedRules,'email_address')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return passes(_vm.confirmPassword)}},model:{value:(_vm.confirmPasswordDto.emailAddress),callback:function ($$v) {_vm.$set(_vm.confirmPasswordDto, "emailAddress", $$v)},expression:"confirmPasswordDto.emailAddress"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{required: true, min:6, max:6, regex: /^[0-9]+$/}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('v-text-field',{attrs:{"append-icon":"mdi-account","name":"otp","type":"text","autocomplete":"new-password","label":_vm.$t('msg.otp'),"error-messages":_vm.$translatedErrorMessages(failedRules,'otp')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return passes(_vm.confirmPassword)}},model:{value:(_vm.confirmPasswordDto.otp),callback:function ($$v) {_vm.$set(_vm.confirmPasswordDto, "otp", $$v)},expression:"confirmPasswordDto.otp"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('v-text-field',{attrs:{"append-icon":"mdi-lock","name":"password","type":"password","autocomplete":"new-password","label":_vm.$t('msg.password'),"error-messages":_vm.$translatedErrorMessages(failedRules,'password')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return passes(_vm.confirmPassword)}},model:{value:(_vm.confirmPasswordDto.password),callback:function ($$v) {_vm.$set(_vm.confirmPasswordDto, "password", $$v)},expression:"confirmPasswordDto.password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('v-text-field',{attrs:{"append-icon":"mdi-lock","name":"confirmPassword","type":"password","autocomplete":"new-password","label":_vm.$t('msg.confirmPassword'),"error-messages":_vm.$translatedErrorMessages(failedRules,'confirmPassword')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return passes(_vm.confirmPassword)}},model:{value:(_vm.confirmPasswordDto.confirmPassword),callback:function ($$v) {_vm.$set(_vm.confirmPasswordDto, "confirmPassword", $$v)},expression:"confirmPasswordDto.confirmPassword"}})]}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"primary mr-5",attrs:{"loading":_vm.loading,"disabled":_vm.loading,"width":"40%"},on:{"click":function($event){return passes(_vm.confirmPassword)}}},[_vm._v(_vm._s(_vm.$t('msg.forgot_password')))]),_c('v-btn',{attrs:{"color":"secondary","loading":_vm.loading,"disabled":_vm.loading,"width":"40%"},on:{"click":_vm.cancelReset}},[_vm._v(_vm._s(_vm.$t('msg.cancel')))])],1)],1)],1)]}}])})],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"top":"","center":"","color":"error"},model:{value:(_vm.invalidUserCredentials),callback:function ($$v) {_vm.invalidUserCredentials=$$v},expression:"invalidUserCredentials"}},[_vm._v(" "+_vm._s(_vm.text)+" "),_c('v-btn',{attrs:{"color":"white","text":""},on:{"click":function($event){_vm.invalidUserCredentials = false}}},[_vm._v(_vm._s(_vm.$t('msg.close_label')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }