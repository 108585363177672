var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":_vm.alignment,"justify":_vm.justify}},[_c('v-col',{attrs:{"align":_vm.alignment,"justify":_vm.justify}},[_c('v-card',{staticClass:"elevation-12 pt-5",attrs:{"width":_vm.width,"outlined":_vm.outlined,"dense":_vm.dense}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-card-text',[_c('v-form',[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_success_message),expression:"show_success_message"}],attrs:{"dense":"","outlined":"","prominent":"","type":"success","color":"green"}},[_c('span',[_vm._v(_vm._s(_vm.$t('msg.successfully_changedPassword')))])]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('v-text-field',{attrs:{"append-icon":"person","name":"name","type":"text","autocomplete":"new-password","label":_vm.$t('msg.username'),"error-messages":_vm.$translatedErrorMessages(failedRules,'username')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return passes(_vm.login)}},model:{value:(_vm.loginDto.username),callback:function ($$v) {_vm.$set(_vm.loginDto, "username", $$v)},expression:"loginDto.username"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('v-text-field',{attrs:{"append-icon":"lock","name":"password","type":"password","autocomplete":"new-password","label":_vm.$t('msg.password'),"error-messages":_vm.$translatedErrorMessages(failedRules,'password')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return passes(_vm.login)}},model:{value:(_vm.loginDto.password),callback:function ($$v) {_vm.$set(_vm.loginDto, "password", $$v)},expression:"loginDto.password"}})]}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading,"disabled":_vm.loading,"width":"100%"},on:{"click":function($event){return passes(_vm.login)}}},[_vm._v(_vm._s(_vm.$t('login')))])],1)]}}])})],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"top":"","center":"","color":"error"},model:{value:(_vm.invalidUserCredentials),callback:function ($$v) {_vm.invalidUserCredentials=$$v},expression:"invalidUserCredentials"}},[_vm._v(" "+_vm._s(_vm.text)+" "),_c('v-btn',{attrs:{"color":"white","text":""},on:{"click":function($event){_vm.invalidUserCredentials = false}}},[_vm._v(_vm._s(_vm.$t('msg.close_label')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }